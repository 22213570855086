@font-face {
  font-family: "Wt-Lc";
  src: local("GT-Walsheim-LC-Regular"),
    url(../src/fonts/GT-Walsheim-LC-Regular.otf);
}

@font-face {
  font-family: "Wt-Lc-Medium";
  src: local("GT-Walsheim-LC-Medium"),
    url(../src/fonts/GT-Walsheim-LC-Medium.otf);
}

@font-face {
  font-family: "Wt-Lc-Bold";
  src: local("GT-Walsheim-LC-Bold"), url(../src/fonts/GT-Walsheim-LC-Bold.otf);
}

@font-face {
  font-family: "Wt-Italic-Bold";
  src: local("GT-Walsheim-LC-Bold-Oblique"),
    url(../src/fonts/GT-Walsheim-LC-Bold-Oblique.otf);
}

@font-face {
  font-family: "Rosa";
  src: local("Rosa-ASQD"), url(../src/fonts/Rosa-ASQD.ttf);
}
body {
  margin: 0;
  padding: 0;
  font-family: "Wt-Lc";
  background-color: #f0f4f5;
  width: 100%;
  max-width: 100%;
  /* overflow-x: hidden; */
}

html {
  width: 100%;
  max-width: 100%;
  /* overflow-x: hidden; */
}

p,
h1,
h2,
h3 {
  padding: 0;
  margin: 0;
}

div {
  box-sizing: border-box;
}

img {
  display: block;
}

ul {
  margin: 0;
  padding: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
:root {
  --mainBlack: #101820;
  --grayDark: #7b949d;
  --blueMain: #0062eb;
  --redMain: #eb0075;
  --greyLight: #f0f4f5;
  --greenMain: #0fca87;
  --boldFont: "Wt-Lc-Bold";
  --mainFont: "Wt-Lc";
  --mediumFont: "Wt-Lc-Medium";
  --italicFont: "Wt-Italic-Bold";
  --rosaFont: "Rosa";
}
input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  color: #7b949d;
  display: inline-block;
  padding: 8px 18px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  background-color: #f0f4f5;
  transition: 0.3s;
  border-radius: 20px;
}
@media (min-width: 768px) {
  input[type="radio"] + label {
    font-size: 20px;
    padding: 22px 14px;
    line-height: 22px;
  }
}

@media (min-width: 1366px) {
  input[type="radio"] + label {
    font-size: 20px;
    padding: 20px 0px;
    line-height: 22px;
    width: 100%;
  }
}

input[type="radio"]:checked + label {
  background-image: none;
  outline: 0;
  color: var(--blueMain);
}
input[type="radio"] + label:hover {
  color: var(--blueMain);
}
